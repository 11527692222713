@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "Goldage";
    src: url("./assets/fonts/Goldage.ttf");
  }

@layer utilities {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }
   /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
  }
}